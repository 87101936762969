import React, { useState } from 'react'
import TypeWriter from 'react-typewriter';
import './css/App.css';
import styled from 'styled-components';


const Container = styled.div`
  min-height: 100vh;
  padding: 0 2rem 2rem 2rem;
  line-height: calc(50px + 2vmin);
  text-transform: uppercase;
  display: flex;
  text-wrap: no-wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  h2 {
    font-size: calc(10px + 2vmin);
    white-space: nowrap !important;
  };
`;


// const promptDelays = [
//   {
//     at: '...',
//     delay: 1000
//   }
// ]


function MissionDescription() {
  const [typingState, setTypingState] = useState(0);

  function changeState() {
    if (typingState === 0){
      setTimeout(setTypingState(0.6), 2000)
    }
  }

  return (
    <Container>
      <TypeWriter typing={0.6} fixed onTypingEnd={changeState}>
        <h1>
          Your first clue:
        </h1>
      </TypeWriter>
      <TypeWriter fixed typing={typingState}>
        <h2>
          A virgin no more <br/>
          his sleek abs lay bare <br/>
          white dust, drunken memories <br/>
          your first clue lies there <br />
        </h2>
      </TypeWriter>
      {/* <TypeWriter  */}
    </Container>
  );
}

export default MissionDescription;
