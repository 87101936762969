import React, { useState } from 'react'
import TypeWriter from 'react-typewriter';
import './css/App.css';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const Container = styled.div`
  min-height: 90vh;
  max-height: 95vh;
  padding: 0 2rem 2rem 2rem;
  line-height: calc(40px + 2vmin);
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;


const StyledLink = styled(Link)({
  color: 'white',
  textDecoration: 'none',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  ':hover': {
    textDecoration: 'underline'
  }
});


const AltLink = styled.p({
  color: 'white',
  textDecoration: 'none',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  padding: '0px',
  margin: '0px',
  ':hover': {
    textDecoration: 'underline'
  }
})


// const promptDelays = [
//   {
//     at: '...',
//     delay: 1000
//   }
// ];


function MissionDescription() {
  const [typingState, setTypingState] = useState(0);

  function changeState() {
    if (typingState === 0) {
      setTimeout(setTypingState(0.6), 2000)
    }
  };

  return (
    <Container>
      <TypeWriter typing={0.6} fixed onTypingEnd={changeState}>
        <h1>
          Your mission, should you choose to accept it...
        </h1>
      </TypeWriter>
      {/* <TypeWriter typing={0.7} fixed delayMap={missionDelays}> */}
      <TypeWriter fixed typing={typingState}>
        <h1>
          Is a scavenger hunt that awaits you in your apartment
        </h1>
      </TypeWriter>
      <div className="fade-in-button-one">
        <StyledLink to="/lily/first-clue">Accept →</StyledLink>
      </div>
      <div className="fade-in-button-two">
        <AltLink onClick={() => alert("That's not actually an option.")}>No I'm lame →</AltLink>
      </div>
    </Container>
  );
}

export default MissionDescription;
