import './css/App.css';
import TypeWriter from 'react-typewriter';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import MissionDescription from './MissionReveal';
import FirstClue from './FirstClue';
import styled from 'styled-components';

var delays = [
  {
    at: '...',
    delay: 500
  }
];

const StyledLink = styled(Link)({
  color: 'white',
  textDecoration: 'none',
  textTransform: 'uppercase',
  letterSpacing: '2px',
  ':hover': {
    textDecoration: 'underline'
  }
})

const Intro = () => (
  <header className="App-header">
    <TypeWriter typing={.4} fixed delayMap={delays}>
      <h1 style={{
        letterSpacing: '2px',
      }}>
        Hello Lily...
      </h1>
    </TypeWriter>
    <div className="fade-in-button">
      <StyledLink to="/lily/mission-details">Begin →</StyledLink>
    </div>
  </header>
)

const ComingSoon = () => (
  <header className="App-header">
    <h1>
      Coming Soon
    </h1>
  </header>
)

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/">
            <ComingSoon />
          </Route>
          <Route exact path="/lily">
            <Intro />
          </Route>
          <Route exact path="/lily/mission-details">
            <MissionDescription />
          </Route>
          <Route exact path="/lily/first-clue">
            <FirstClue />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
